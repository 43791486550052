@import "../../styles/mixin.scss";

.container {
  padding: 20px;
  color: #ffffff;
  .inner-title {
    font-size: 24px;
    width: 139px;
    font-family: Helvetica;
    color: #ffffff;
    font-weight: 800;
    text-decoration: none solid rgb(255, 255, 255);
  }
  .inner-text {
    margin: 20px 0px;
    font-family: Helvetica;
    font-size: 16px;
    color: #adadad;
  }
  .inner-text-bottom {
    margin: 60px 0px;
    min-height: 200px;
    font-family: Helvetica;
    font-size: 16px;
    color: #adadad;
  }
  .service-list {
    margin: 30px 0px;
    min-height: 40%;
    .service-table {
      @include mobile {
        width: 100%;
      }
      @include tablet {
        width: 100%;
      }
      @include not-mobile {
        width: 70%;
      }
    }
  }
}
