* {
  font-family: Helvetica;
  padding: 0;
  margin: 0;
}
body {
  margin: 0;
  background-color: #000000;
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Helvetica";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.parent-component {
  min-height: 100vh;
  position: relative;
}
.parent-component > .main-container {
  min-height: 80vh;
  display: flex;
  width: 100%;
  color: #ffffff;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
