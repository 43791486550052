@import "../../styles/mixin";
.detail-section {
  padding: 10px 0px;
  @include mobile {
    border-top: 1px solid #666666;
    border-bottom: 1px solid #666666;
    width: 100%;
  }
  @include not-mobile {
    width: 20%;
    min-height: 280px;
  }
  .detail-header {
    img {
      height: 10px;
      margin: 0px 10px;
    }
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
    height: 18px;
    font-family: Helvetica;
    font-size: 18px;
    color: #ebac2f;
    text-decoration: none solid rgb(235, 172, 47);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .detail-text {
    font-size: 16px;
  }
}
