@import "../../../styles/mixin";
.orders-container {
  margin-top: 10px;
  padding: 10px 0px;
  height: 100%;

  .orders-list-container {
    .web-order {
      display: none;
      @include not-mobile {
        display: block;
      }
    }
    .mobile-order {
      display: none;
      @include mobile {
        display: block;
      }
    }
    #status-completed {
      font-family: Helvetica;
      font-size: 14px;
      color: #4caf50;
      font-weight: 700;
    }
    #status-progress {
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 700;
      color: #347cff;
    }
    .loader-div {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;
    }
  }
  .user-title {
    font-family: Helvetica;
    font-size: 20px;
    color: #ffffff;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .detail-container {
    .order-section {
      margin-top: 20px;
    }
    .order-detail-item {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-family: Helvetica;
      font-size: 16px;
      color: #ffffff;
      padding: 5px 0px;
      .value {
        color: #6c69d1;
      }
    }
    @include not-mobile {
      width: 30%;
    }
    @include mobile {
      width: 100%;
    }
  }
}
