@import "../../styles/_mixin.scss";
@mixin nav-item {
  display: inline;
  background-size: cover;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  background-color: #ebac2f;
  width: 100%;
  padding: 10px 0px;
}
.dashboard-main-container {
  @include not-mobile {
    width: 100%;
    padding: 20px !important;
  }
  @include mobile {
    width: 100%;
    padding: 0px !important;
  }

  .dashboard-nav-bar {
    display: flex;
    width: 100%;
    color: #ffffff;
    .nav-list {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      .nav-item {
        @include nav-item();
      }
      .active {
        @include nav-item();
        border-bottom: 2px solid #ffffff;
      }
    }
  }
  .dashboard-component {
    min-height: 50vh;

    @include mobile {
      padding: 20px;
    }
  }
}
