$tablet-width: 768px;
$computer-width: 992px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$computer-width - 1px}) {
    @content;
  }
}

@mixin computer {
  @media (min-width: #{$computer-width}) {
    @content;
  }
}

@mixin not-mobile {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}
