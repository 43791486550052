@import "../../styles/mixin";
@mixin request-btn-style($width, $height) {
  width: $width;
  height: $height;
  font-family: Helvetica;
  font-size: 16px;
  color: #ffffff;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  background-color: #ebac2f;
  &:hover {
    background-color: #ebac2f;
  }
}
.header-buttons {
  .request-button {
    @include request-btn-style(203px, 29px);
  }
}
.container-request {
  width: 100%;
  display: flex;
  .request-button {
    @include request-btn-style(100%, 45px);
    margin: 10px 0px;
  }
}

.detail-button-order {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  @include mobile {
    width: 100%;
  }
  @include not-mobile {
    width: 30%;
  }
  .detail-btn {
    font-family: Helvetica;
    font-size: 16px;
    border-radius: 4px;
    background-color: #6200ee;
    background-size: cover;
    color: #ffffff;
    text-transform: uppercase;
    margin: 5px 0px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    &:hover {
      background-color: #6200ee;
    }
  }
}
.detail-button-invoice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  .detail-btn {
    width: 30%;
    @include mobile {
      width: 60%;
    }
    @include not-mobile {
      width: 20%;
    }
    font-family: Helvetica;
    font-size: 16px;
    border-radius: 4px;
    background-color: #6200ee;
    background-size: cover;
    color: #ffffff;
    text-transform: uppercase;
    margin: 5px 0px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    &:hover {
      background-color: #6200ee;
    }
  }
}
