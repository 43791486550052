@import "../../styles/mixin";
.slider {
  display: none;

  @include mobile {
    display: flex;
  }
}
.header-logo {
  cursor: pointer;
  display: flex;
  .header-logo-text {
    width: 50px;
    height: 28px;
    font-family: Helvetica;
    font-size: 24px;
    color: #ffc107;
    font-weight: 700;
  }
}
.header-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
  .header-nav-button {
    display: none;
    .profile-div {
      position: relative;
      .profile-section {
        position: absolute;
        top: 10px;
        right: 0;
        width: 270px;
        height: 352px;
        background-color: #ededed;
        color: #000000;
        z-index: 10;
        .profile-btn {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          .close-button {
            width: 30px;
          }
        }
        .action-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          .slider-button {
            font-size: 16px;
            width: 80%;
            img {
              margin-right: 5px;
            }
          }
        }
      }
    }
    @include not-mobile {
      display: flex;
    }
  }
}
