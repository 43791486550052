@import "../../../styles/mixin";
.invoice-container {
  margin-top: 10px;
  padding: 10px 0px;
  height: 100%;
  .invoice-basic {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .invoice-status {
      font-size: 20px;
      font-weight: 800;
      text-transform: uppercase;
      color: #4caf50;
      text-decoration: none solid rgb(76, 175, 80);
      text-align: right;
    }
  }
  .invoice-list-container {
    .web-invoice {
      display: none;
      @include not-mobile {
        display: block;
      }
    }
    .mobile-invoice {
      display: none;
      @include mobile {
        display: block;
      }
    }

    #status-completed {
      font-family: Helvetica;
      font-size: 14px;
      color: #4caf50;
      font-weight: 700;
    }
    #status-progress {
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 700;
      color: #347cff;
    }
  }
  .invoice-title {
    font-family: Helvetica;
    font-size: 20px;
    color: #ffffff;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .invoice-due {
    font-family: Helvetica;
    font-size: 20px;
    color: #347cff;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .invoice-list-container {
    @include not-mobile {
      width: 100%;
    }
    @include mobile {
      width: 100%;
      .dynamic-col {
        display: none;
      }
    }
    .loader {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 10px 0px;
    }
    .total-section {
      margin-top: 10px;
      padding: 5px 0px;
      border-top: 1px solid #ffffff;
      border-bottom: 1px solid #ffffff;
      display: flex;
      justify-content: space-between;
    }
  }
}
