@mixin pay-button($bg) {
  font-family: Helvetica;
  font-size: 14px;
  color: #ffffff;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  background-color: $bg;
  border-radius: 2px;
  background-size: cover;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: $bg;
  }
}
.paid {
  .pay-button {
    @include pay-button(#4caf50);
  }
}
.pay {
  .pay-button {
    @include pay-button(#347cff);
  }
}
