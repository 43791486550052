@import "../../styles/mixin.scss";

@mixin contactContainer($paddingX, $paddingY) {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: $paddingY $paddingX;
  width: 100%;
}

.contact-us-container {
  @include mobile {
    @include contactContainer(20px, 20px);
  }
  @include not-mobile {
    @include contactContainer(40px, 40px);
  }
  @include tablet {
    @include contactContainer(40px, 40px);
  }
  .contact-us-inner {
    width: 100%;
    .inner-title {
      font-size: 24px;
      width: 139px;
      font-family: Helvetica;
      color: #ffffff;
      font-weight: 800;
      text-decoration: none solid rgb(255, 255, 255);
    }
    .form-container {
      margin-top: 10px;
      @include not-mobile {
        width: 50%;
      }
      @include tablet {
        width: 50%;
      }
      .contact-form {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 15px;
        .phone-label {
          color: #adadad;
          margin-top: 15px;
          font-size: 15px;
        }
        .user_email {
          height: 50px;
        }
        .flag-dropdown {
          background: transparent;
          border: none;
        }
        .submit-container {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          padding: 10px 0px;
        }
        .message-size {
          font-size: 12px;
          color: #347cff;
          line-height: 24px;
          text-align: right;
        }
        .phone-number {
          margin-top: 10px;
        }
      }
    }
  }
}
.react-tel-input .country-list .country.highlight {
  background-color: transparent !important;
}
.react-tel-input .country-list {
  background-color: #000000 !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}
.react-tel-input .country-list .country:hover {
  background-color: #ffffff !important;
  color: #000000;
}
