@import "../../../styles/mixin";
.home-container {
  margin-top: 10px;
  padding: 10px 0px;
  height: 100%;
  .user-title {
    font-family: Helvetica;
    font-size: 20px;
    color: #ffffff;
    font-family: Helvetica;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .detail-container {
    .order-section {
      margin-top: 20px;
    }
    .user-account-item {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-family: Helvetica;
      font-size: 16px;
      color: #ffffff;
      .value {
        color: #6c69d1;
      }
    }
    @include not-mobile {
      width: 30%;
    }
    @include mobile {
      width: 100%;
    }
  }
}
