@import "../../styles/_mixin.scss";
.footer {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0;
  @include mobile {
    flex-direction: column;
    justify-content: center;
    padding: 10px 0px;
    text-align: center;
    position: unset;
  }
  .footer-inner {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include mobile {
      flex-direction: column;
      justify-content: center;
      padding: 10px 0px;
      text-align: center;
    }
    @include not-mobile {
      justify-content: space-between;
      padding: 10px 30px;
      font-size: 14px;
    }
  }
}
