.form-container {
  .submit-button {
    margin: 0px 10px;
    color: #ffffff;
    font-family: Helvetica;
    height: 36px;
    border-radius: 4px;
    background-color: #6200ee;
    background-size: cover;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    &:hover {
      background-color: #782fdf;
    }
  }
}
