@import "../../styles/mixin.scss";

@mixin loginContainer($paddingX, $paddingY) {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: $paddingY $paddingX;
  width: 100%;
}

.login-container {
  @include mobile {
    @include loginContainer(20px, 20px);
  }
  @include not-mobile {
    @include loginContainer(40px, 40px);
  }
  @include tablet {
    @include loginContainer(40px, 40px);
  }
  .login-inner {
    width: 100%;
    .inner-title {
      font-size: 24px;
      width: 139px;
      font-family: Helvetica;
      color: #ffffff;
      font-weight: 800;
      text-decoration: none solid rgb(255, 255, 255);
    }
    .form-container {
      @include not-mobile {
        margin-left: 20%;
        width: 30%;
      }
      @include tablet {
        margin-left: 20%;
        width: 50%;
      }
      .login-form {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 15px;
        .submit-container {
          display: flex;
          width: 100%;
          justify-content: flex-end;
        }
        .forgot-link {
          width: 114px;
          height: 16px;
          margin-top: 10px;
          font-family: Helvetica;
          font-size: 14px;
          color: #6c69d1;
          font-style: italic;
          text-decoration: none solid rgb(108, 105, 209);
        }
      }
    }
  }
}
