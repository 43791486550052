@import "../../styles/mixin";
.container {
  padding: 20px;
  color: #ffffff;
  .container-header {
    .header-text {
      max-width: 400px;
      font-family: Helvetica;
      color: #ffffff;
      padding: 0px 0px 27px 0px;
      .main-text {
        font-size: 72px;
        color: #ffffff;
        text-decoration: none solid rgb(255, 255, 255);
      }
      .secondary-text {
        max-width: 600px;
        font-size: 32px;
        text-decoration: none solid rgb(255, 255, 255);
      }
    }
  }
  .container-details {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    @include mobile {
      flex-direction: column;
    }
    @include not-mobile {
      flex-direction: row;
    }
  }
}
